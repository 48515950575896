<template>
  <div class="page-background">
    <main-header :title="'Settings'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <v-form v-model.trim="valid" ref="formChangeEmail">
        <div>
          <h3 class="from-headers">Change Email</h3>
          <v-text-field outlined label="Email" dark class="input-text" type="email"   v-model="member.email" :rules="emailRules"></v-text-field>
        </div>
        <div class="row mb-5" >
         <div class="col-6">
           <v-btn class="btn-settings" >Cancel</v-btn>
         </div>
          <div class="col-6">
            <v-btn color="white" class="btn-settings-white" @click="sendVerification">Send Verification</v-btn>
          </div>
        </div>

        <div>
          <h3 class="from-headers">Email Notifications</h3>
          <v-switch inset color="green" value="green" label="My plan information"></v-switch>
          <v-switch color="green" value="green" inset label="Apaly"></v-switch>
        </div>
      </v-form>

    </div>

  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      member:{},
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid:false
    };
  },
  components: {
    mainHeader,
  },
  created() {
    this.member=JSON.parse(localStorage.getItem("user"))
  },
  methods: {
    sendVerification(){
      this.$refs.formChangeEmail.validate();
      if (this.valid){}
      this.$router.push('/account/alert/verification');
    }
  },

};
</script>
<style scoped lang="scss">
.mobile-edit-form {
  padding-bottom: 150px !important;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings-white{
  background-color:white !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: #16407a;
  float:right
}

.v-input__slot > label {
  color: white !important;
}

</style>
