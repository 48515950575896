<template>
    <div>

        <v-dialog
        v-model="dialog"
        scrollable
        persistent
        width="400px"
      >
        <v-card>
          <span class="text-end pa-1"><v-icon role="button" @click="closeModal()" v-text="'mdi-close'" slot="end" ></v-icon></span>
          <v-card-title class="justify-center">
            <span >Please confirm your plan</span>

            
          </v-card-title>
          <v-card-text style="height: 200px;">
                <v-list>
                    <v-list-item role="button" @click="selectPlan(plan)" v-for="(plan,index) in plans" :key="index" class="text-center d-flex justify-center primary--text ">{{plan.name}}</v-list-item>
                </v-list>
          </v-card-text>
          <!-- <v-card-actions class="d-flex justify-center">
            <p @click="openNewMemberPlanModal()" class="text-center primary--text">Not on the list? ></p>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    

      <v-dialog
      v-model="newMemberPlanModalStatus"
      width="400px"
      
    >
      <v-card height="350">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="newMemberPlanModalStatus = false" v-text="'mdi-close'" slot="end" ></v-icon>
        </v-card-title>
        <v-card-text>
          <h2 class="font-weight-regular font-size-24">Insert Your plan ID</h2>
          <p class="font-size-16 mt-2 text-grey">So we can pull your benefits</p>
          <v-form v-model="newPlanForm" ref="newPlanForm">
            <v-text-field placeholder="Plan ID" outlined label="Plan ID" v-model="planID" :rules="requiredRules"></v-text-field>
  
          </v-form>
          <span v-if="planError" style="color: red"> {{errorText}}.</span>
        </v-card-text>
        
        <v-card-actions class="d-flex justify-center align-center pb-5">
          <span role="button" @click="newMemberPlanModalStatus = false" class="primary--text mr-10">back</span>
          <v-btn @click="getPlanFromId()" color="primary" :disabled="!newPlanForm">Pull Benefits</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <!-- <members-new-plan-modal @closeMemberNewPlanModal="closeMemberNewPlanModal" v-if="newMemberPlanModalStatus"  :newMemberPlanModalStatus="newMemberPlanModalStatus" />
      <confirm-information-modal v-if="confirmInformationModal" :confirmInformationModal="confirmInformationModal" /> -->
    </div>
</template>
<script>
import api from "@/services/api";

import MembersNewPlanModal from './MembersNewPlanModal.vue';
import ConfirmInformationModal from './ConfirmInformationModal.vue';
export default {
components: { MembersNewPlanModal,ConfirmInformationModal },
props:{
    membersPlanModalStatus:{
        required:true
    },
},
  data() {
    return {
      planError: false,
      errorText: '',
      confirmInformation: true,
      validInfo: false,
      validInsurance: false,
      confirmInfo: {},
      insuranceInfo: {},
      eligibilityStep: 1,
      genders: [
        { text: "Female", value: "F" },
        { text: "Male", value: "M" },
      ],
      relationships: [
        { text: "Self", value: "self" },
        { text: "Spouse", value: "spouse" },
        { text: "Dependen", value: "dependent" },
      ],
      IdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{3}\-[0-9]{6}/.test(v) ||
          "ID should contain 9 digits, Please enter your ID in XXX-XXXXXX format",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      phoneRules:[
      (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
      ],

      newPlanForm:false,
      
      dialog:this.newMemberPlanModalStatus,
        planID:null,
        requiredRules: [(v) => !!v || "This Field is required"],

        dialog:this.membersPlanModalStatus,
        newMemberPlanModalStatus:false,
        plans:[],
        selectedPlan:null,
        confirmInformationModal:false
    };
  },
  methods: {
    getMemberInfo(){
      api().get(`/member/app/member/info`).then((res)=>{
          if(res){
            console.log('res', res)
          }
        }).catch((err)=>{
          if (err.response.data) {
              this.error = err.response.data;
            } else {
              this.error = "Something went wrong. Please try again later.";
            }
        });
    },
    showSlash(event) {
      if ((event.target.value.length) == 3) {
        event.target.value += '-';
      }
    },
    nextEligibilityStep(){
      this.eligibilityStep = 2;
    },
    selectPlan(plan){
      this.$emit('selectedPlan', plan);
      this.selectedPlan = plan
      // if(this.$store.getters.getSelectedProvider != null){
      //   // this.closeModal()
      //   this.openConfirmInformationModal()
      //   this.getMemberInfo()
      // }else{
      //   this.$router.push('/marketplace')
      // }
    },
    getPlanFromId(){
      api().get(`/member/app/plan/`+this.planID).then((res)=>{
          if(res){
            // this.$emit('openConfirm', true);
            if(res.data.err){
              this.planError = true;
              this.newPlanForm = false;
              this.planID= ''
            this.errorText = res.data.err;
            }
            else{
              this.planError = false;
              this.$emit('selectedPlan', res.data);
            }
          }
        }).catch((err)=>{
         console.log('err',err)
        });
    },
    openConfirmInformationModal(){
      this.confirmInformationModal = true
    },
    closeModal(){
        this.$emit('closeMemberPlanModal')
        this.dialog=false
    },
    openNewMemberPlanModal(){
        this.newMemberPlanModalStatus = true
    },

    getPlans(){
      api().get(`/member/app/plans`).then((res)=>{
          if(res){
            this.plans = res.data;
            if(this.plans.length == 1){
              this.dialog = false;     
               this.$emit('selectedPlan', this.plans[0]);
            }
          }
        }).catch((err)=>{
          if (err.response.data) {
              this.error = err.response.data;
            } else {
              this.error = "Something went wrong. Please try again later.";
            }
        });
    }

  },
  created() {
    this.getPlans()
  },
};
</script>