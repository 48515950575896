<template>
  <div class="page-background-alert ">

    <!-- <img class="apaly-logo" src="/apaly.png" alt=""> -->


    <div  class="row alert-text" v-if="type == 'loading'" style="left: 121px;
    top: 390px;" >
      <v-progress-circular class="mt-18" indeterminate :size="75" ></v-progress-circular>
    </div>

    <div  v-if="type == 'verification'" >
      <div  class="row alert-text" >
        Great job! <br> Check your email for a verification link.
      </div>
      <div  class="row alert-buttons"    >
        <v-btn class="btn-blue" @click="resendVerificationLink" >Resend verification link</v-btn>

        <v-btn class="btn-white" @click="$router.push({name:'MemberLogin'})">Login</v-btn>
      </div>
    </div>


    <div  class="row alert-text" style="width: 207px"  v-if="type == 'updated-info'" >
     <div>Nice Work! <br> You have updated your information.</div>
      <br>
      Your account has been linked to <br> {{ employerName }}.
    </div>


    <div  class="row alert-text" style="width: 207px"  v-if="type == 'error' " >
      Oops... <br> We could not verify your information.
    </div>

    <div class="d-flex footer"  v-if="type == 'error' ">
      <div class="col-6">
        <v-btn color="white" class="footer-btn " @click="$router.push('/auth/personal-info');">Revise information</v-btn>
      </div>
      <div class="col-6">
        <v-btn class="footer-btn-cancel"  @click="$router.push({name:'WelcomePage'})">Fix later</v-btn>
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{textInSnackbar}}
    </v-snackbar>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import api from "@/services/api";

export default {
  data() {
    return {
      type:this.$route.params.type,
      email:'',
      snackbar:false,
      textInSnackbar:'',
      employerName:''
    };
  },
  computed:{
    ...mapGetters(["getEmail"]),
  },
  components: {
  },
  methods: {
    resendVerificationLink(){
      this.email = this.$store.getters.getEmail;
      api().get(`/member/app/send/verification?email=${this.email}`).then((res)=>{
        if(res){
          this.snackbar = true
          this.textInSnackbar='Verification link sent to email '+this.email;
        }else{
          this.snackbar = true
          this.textInSnackbar='No member found';
        }
      }).catch((err)=>{
        console.log('err',err)
      });
    }
  },
  created() {
    if (this.type === 'updated-info'){
      this.employerName = JSON.parse(localStorage.getItem("employerName"))
    }
  },
};
</script>

<style scoped lang="scss">

.page-background-alert {
  height: 100vh;
  background-color:  #16407A;
}


.apaly-logo{
  position: absolute;
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 315px;
}

.alert-buttons{

  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 323px;
  height: 80px;
  left: 44px;
  top: 413px;

}

.btn-blue{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-white{
  background-color:white !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: #16407A;
  float:right
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn{
  border-radius: 23px;
  width: 100%;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
</style>