<template>
   <div class="set_password_layout">

   <div  class="register-page-container">

      <div class="register-page-form">

        <!-- HEADER -->
        <div class="sign_header_container">
          <div class="sign_header">
            <!-- BACK BUTTON -->
            <div class="sign_header_back">
              <!-- <img src="/svg/backArrow.svg" role="button" @click="$router.go(-1)"><br> -->
            </div>

            <!-- LOGO -->
            <div class="sign_header_logo">
              <img src="/apaly-logo.png" >
            </div>
          </div>
        </div>

        <!-- FORM -->
        <div class="form_desk_up">
          <v-form ref="form" v-model.trim="valid" class="set_password_form">
            <div>

              <p class="font-24 pb-0 mb-0">
                E-mail confirmed.
              </p>
              <p class="font-20 pb-0 mb-0">
                Now let's create your password:
              </p>

              <div  class="col-12 pl-0 pr-0 no-list-style">
                Requirements:
                <ul class="ml-0 pl-0 font-14">
                  <li :class="isLengthValid ? 'valid-text text-decoration-none' : ''">
                    <v-icon v-if="isLengthValid" size="small" color="green">mdi-check</v-icon>
                    <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
                    At least 8 characters</li>
                  <li :class="isCapitalLetterValid ? 'valid-text' : ''">
                    <v-icon v-if="isCapitalLetterValid" size="small" color="green">mdi-check</v-icon>
                    <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
                    At least one upper case letter</li>
                  <li :class="isLowercaseLetterValid ? 'valid-text' : ''">
                    <v-icon v-if="isLowercaseLetterValid" size="small" color="green">mdi-check</v-icon>
                    <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
                    At least 1 lowercase letter</li>
                  <li :class="isSpecialCharacterValid ? 'valid-text' : ''">
                    <v-icon v-if="isSpecialCharacterValid" size="small" color="green">mdi-check</v-icon>
                    <v-icon v-else size="small" style="font-size: 8px;">mdi-circle</v-icon>
                    At least 1 special character - ! @ # $ % etc</li>
                </ul>
              </div>

              <div class="col-12 pl-0 pr-0">
                <label for="" class="tiny-font">New Password</label>
                <v-text-field
                  outlined
                  v-model.trim="password"
                  required
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :rules="passRules"
                  style="border-radius: 8px;"
                  @input="confirmPass()"
                ></v-text-field>
              </div>

              <div class="col-12 mb-0 pl-0 pr-0">
                <label for="" class="tiny-font"> Confirm New Password</label>
                <v-text-field
                  outlined
                  v-model.trim="confirmpassword"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  :rules="confirmPassRules"
                  style="border-radius: 8px;"
                  @input="confirmPass()"
                ></v-text-field>
              </div>
              <v-col v-if="error.length > 0" class="mt-3 mb-3 col-md-12">
                <v-alert dense outlined type="error">
                  {{ error }}
                </v-alert>
              </v-col>

              <!-- <div class="col-12  justify-content-end text-end pl-0 pr-0">
                <v-btn color="#0069F3" width="300" :disabled="!valid" style="border-radius: 8px; color:white" @click="send()">
                  Set Password
                </v-btn>
              </div> -->
              <div class="dialog_buttons reset_pass_btn_container">
                <!-- <button class="dialog_double_button dialog_btn_second_4 dialog_btn_floatLeft reset_pass_btn" @click="$router.push('/login')" >
                  Back to Login
                </button> -->
                <button :disabled="!valid" class="dialog_single_button dialog_btn_primary dialog_btn_floatRight reset_pass_btn" @click.prevent="send()" >
                  Set Password
                </button>
              </div>
              
            </div>
          </v-form>
        </div>

        
        <!-- <img class="apaly-logo hide-mobile ml-auto" src="/apaly-logo.png" height="64"> -->

      </div>

   </div>


    <!-- DIALOG/MODAL -->
    <v-dialog v-model.trim="rightPass" width="500" class="success-modal" persistent>
      <v-card>
        <v-card-text class="success-modal pt-5">
          <!-- <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="backToLogin()">mdi-close</v-icon></div> -->
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="closeTab()">mdi-close</v-icon></div>

          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0" style="color:#014D00">
              Password Saved Successfully!
            </span>
            <!-- <span class="text-start font-16 mt-2 font-weight-300  mb-5">
              Please continue to login.
            </span> -->
            <div class="text-center text--white mt-6">
              <!-- <v-btn color="#0069F3" class="done-btn" @click="backToLogin()">
                Continue to Login
              </v-btn> -->
              <v-btn color="#0069F3" class="done-btn" @click="closeTab()">
                Close
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="alreadyHavePasswordModal" width="500" persistent class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <!-- <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="backToLogin()">mdi-close</v-icon></div> -->
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="closeTab()">mdi-close</v-icon></div>
          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0" style="color:#014D00">
              Success. Your e-mail is confirmed.
            </span>
            <div class="text-center text--white mt-6">
              <!-- <v-btn color="#0069F3" class="done-btn" @click="backToLogin()"> -->
              <v-btn color="#0069F3" class="done-btn" @click="closeTab()">
                <!-- Continue to Login -->
                Close
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" ref="error_modal" ></errorModal>

    <v-dialog v-model.trim="tokenExpired" width="500" persistent class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <!-- <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="backToLogin()">mdi-close</v-icon></div> -->
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="closeTab()">mdi-close</v-icon></div>
          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0 dialog_title dialog_error" >
                Your account is already verified, and this link has expired. Please login.
            </span>
            <div class="text-center text--white mt-6">
              <v-btn color="#0069F3" class="done-btn" @click="closeTab()">
                <!-- Continue to Login -->
                Close
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import Vue from "vue";
import api from "@/services/api";
import errorModal from '../../../Common/SomethingWentWrong.vue'

import { mapActions } from "vuex";
export default {
  components:{
    errorModal
  },
  data() {
    return {
      alreadyHavePasswordModal: false,
      somethingWrong: false,
      error: "",
      checkbox: false,
      pdfDialog: false,
      showContract: false,
      tokenAcc: "",
      confirmed: false,
      wrongPass: false,
      rightPass: false,
      email: "",
      changePassEmail: "",
      check1: false,
      check2: false,
      check3: false,
      contains_uppercase: false,
      contains_special_character: false,
      password: "",
      confirmpassword: "",
      valid: false,
      show: false,
      show1: false,
      error: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Password does not meet the security requirements",
        (v) => (v && /[A-Z]{1}/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[a-z]/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "Password does not meet the security requirements",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Password does not meet the security requirements",
        (v) => (v && /[A-Z]{1}/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[a-z]/.test(v)) || "Password does not meet the security requirements",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "Password does not meet the security requirements",
        (v) => v == this.password || "Password must match",
      ],
      passsError: "",
      error: "",
      newToken: null,
      tokenExpired: false,
    };
  },
  computed: {
    token() {
      return this.$route.params;
    },
    isLengthValid() {
      return this.password.length >= 8;
    },
    isCapitalLetterValid() {
      return /[A-Z]/.test(this.password);
    },
    isLowercaseLetterValid() {
      return /[a-z]/.test(this.password);
    },
    isSpecialCharacterValid() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
  },
  created() {
    this.tokenAcc = this.token;
    if (this.$route.query.token) {
      api()
              .post(`/member/app/validate/token`, {
                token: this.$route.query.token,
              })
              .catch((err) => {
                // if(err.response.status == 401){
                //   window.location = '/expired'       
                // }
                //   window.location = '/expired'       
                    this.tokenExpired = true;
              })
              .then((res) => {
                if(res){
                  //nothing
                  this.newToken = res.data.newToken

                  if(res.data.password){
                    this.alreadyHavePasswordModal = true;
                    
                  }
                }
              });
    }
  },
  methods: {
    updatePassword(value) {
    this.password = value;
  },
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    reason() {
      this.reasonDialog = true;
    },
    openPdfModal() {
      const url =
        "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads//admin/contracts/terms.pdf";
      this.pdfUrl = url;
      this.pdfDialog = true;
    },
    send() {
      this.valid = false;
      if (this.newToken) {
            api()
              .post(`/member/app/password/reset`, {
                password: this.password,
                token: this.newToken,
              })
              .catch((err) => {
                console.log('err.response.status', err.response.status);
                if(err.response.status == 403){
                    this.somethingWrong = true;   
                }
              })
              .then((res) => {
                if(res){
                   this.rightPass = true;
                }
              })
              .finally(() => {
                this.valid = true;
          });
          }
    },
    backToLogin() {
      window.location = '/login';
    },
    closeTab() {
      window.location = '/login';
      // We close this page after setting or confirming email because by going to login page, the token gets removed
      // Sample scenario: user opens Home then remembers to set his/her password. After setting password, they got redirected to login.
      // But user decides to go back to the open tab that was navigated to Home and now suddenly the buttons dont work.
      // That because the token has been removed.
      
      // Solution: Just close this page after setting password. K.N

        // Scripts may close only the windows that were opened by them.
        //   window.close();
    },
    checkPassword() {
      if (this.password.length >= 8) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
      this.contains_uppercase = /[A-Z]/.test(this.password);

      if (this.contains_uppercase == true) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.contains_special_character = format.test(this.password);
      if (this.contains_special_character == true) {
        this.check3 = true;
      } else {
        this.check3 = false;
      }
    },
    confirmPass() {
      const updatedRules = [...this.confirmPassRules];
      if (this.confirmpassword !== this.password) {
        updatedRules[6] = "Password must match";
    } else {
      updatedRules[6] = true;
    }
    this.confirmPassRules = updatedRules;
    this.somethingWrong = false;  
    },
  },
};
</script>
<style scoped>
  .hide-desktop{
  display: none !important;
}
label{
    color: #333333 !important;
    font-weight: 400; 
  }
.no-list-style li {
  list-style-type: none !important;
}
.reset-pass-page {
  padding: 64px;
}
.apaly-logo {
  width: 130px;
}
.reset-pass-container {
  margin: 0 auto;
}
.reset-pass-logo {
  left: 30px;
}
.reset-pass-title {
  text-align: center;
}
.display {
  display: grid;
}

.valid-text {
  color: green;
}

.confirm-btn {
  color: white;
  width: inherit;
}
.done-btn {
  color: white;
  width: 170px;
}
.org {
  margin-top: 20vh;
}
.no-btn {
  width: 170px;
  border: 1px solid #16407a;
}
.color-black {
  color: black;
}
.desc {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.reset-pass-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reset-pass-page-right-side-form {
  width: 40%;
  align-self: center;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .reset-pass-logo{
    display: flex;
    justify-content: space-between;
  }
  .apaly-logo{
    height: 40px;
    width: 70px;
  }
  .hide-desktop{
  display: flex !important;
}
  .hide-mobile{
    display: none !important;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .reset-pass-page-logo {
    display: flex;
    justify-content: space-between;
  }
  .reset-pass-page {
    padding: 24px;
  }
  .reset-pass-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .reset-pass-page-container {
    width: 100%;
  }
  .reset-pass-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .reset-pass-page-error-box {
    padding: 10px 0;
  }
  .reset-pass-page-right-side-form {
    width:100%;
    padding: 0;
  }
  .reset-pass-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
