<template>
  <div class="page-background">
    <main-header :title="'Contact Us'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <v-form v-model.trim="valid" ref="formContactUs">
        <div>
          <h3 class="from-headers">Send a message to support
            <br>
            <span class="from-sub-headers">Contact us by completing this form. You can alternatively email contact@apaly.com.</span>
          </h3>
          <v-text-field type="email" outlined label="Email" dark v-model="member.email" :rules="emailRules" ></v-text-field>
          <v-textarea  outlined label="Message" v-model="message" dark :rules="requiredRules"></v-textarea>
        </div>
      </v-form>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn color="white" class="footer-btn" @click="submit()">Submit</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      member:JSON.parse(localStorage.getItem("user")),
      message:'',
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      valid: false,
    };
  },
  components: {
    mainHeader,
  },
  created() {},
  methods: {
    submit(){
      this.$refs.formContactUs.validate();
      if(this.valid){

      }
    }
  },

};
</script>
<style scoped lang="scss">
.mobile-edit-form {
  padding-bottom: 150px !important;
}

.page-background {
  background-color: #001e46;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings-white{
  background-color:white !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: #16407a;
  float:right
}

.v-input__slot > label {
  color: white !important;
}

</style>
